
/* External Components */
import React, {useState, useEffect} from 'react';
import moment from 'moment';
import 'moment/locale/nb';
import { Trip } from '../../model/interfaces';
import { getForecast } from '../../services/tripService';
import wind from "../../assets/wind.svg"

const Weather = (props: {trip: Trip, latLng: any}) => {
      const [forecast, setForecast] = useState<any>(null);

      useEffect(() => {
            if (props.latLng) {
                  getForecast(props.latLng).then((result) => {
                        const tripDate = props.trip.attributes.Tid;
                        const dates: number[] = result.data.properties.timeseries.map((d: any) => moment(d.time).valueOf());

                        let min = 0; 
                        let chosen = 0;
                        dates.map((date, index) => {
                              min = Math.abs(dates[chosen] - tripDate);
                              if (Math.abs(date - tripDate) < min) {
                                    chosen = index;
                              }
                        })

                        setForecast(result.data.properties.timeseries[chosen]);
                  });
            }
      }, [props.latLng]);

       const getSymbol = () => {
            if (forecast.data.next_1_hours) {
                  return forecast.data.next_1_hours.summary.symbol_code;
            } else if (forecast.data.next_6_hours) {
                  return forecast.data.next_6_hours.summary.symbol_code;
            } else if (forecast.data.next_12_hours) {
                  return forecast.data.next_12_hours.summary.symbol_code;
            } else {
                  return null;
            }
       }

      if (!forecast) {
            return <div className="widget">Laster værmelding..</div>;
      }

    return (
      <div className="widget flex flex-col items-center p-3 mr-2">
            <div>
                  {moment(forecast.time).format('LLLL')}
            </div>
            <div>
                  {getSymbol() &&
                        <img width={80} src={`https://api.met.no/images/weathericons/svg/${getSymbol()}.svg`} />
                  }
            </div>
            <div className="text-3xl">
                  {Math.floor(forecast.data.instant.details.air_temperature)}°C
            </div>
            <img className="m-auto" style={{transform: `rotate(${forecast.data.instant.details.wind_from_direction}deg)`}} width={40} src={wind}/>
            <div className="text-xl">
                  {Math.floor(forecast.data.instant.details.wind_speed)} m/s
            </div>
      </div>
    );
    
}
  
  export default Weather;
