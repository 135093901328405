
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import { AppContext } from "../../state/context";
import "./TripList.css";
import { getTrips, getTripsSignedUp, signUp, getMyTrips } from '../../services/tripService';
import { Trip } from '../../model/interfaces';
import Difficulty from '../Difficulty/Difficulty';
import ImageIcon from '../../assets/image.svg';
import PersonIcon from '../../assets/person.svg';
import ArrowIcon from '../../assets/arrow.svg';
import moment from 'moment';
import 'moment/locale/nb';
import { useHistory, useLocation } from "react-router-dom";
import Loading from '../Loading/Loading';
import { isMobile } from 'react-device-detect';

const TripList = () => {
      const context = useContext(AppContext);
      const history = useHistory();
      const location = useLocation();

      const [tripsSignedUp, setTripsSignedUp] = useState([]);
      const [myTripIds, setMyTripIds] = useState<any>([]);
      const [loading, setLoading] = useState(false);
      const [showMine, setShowMine] = useState(true);
      const [showUpcoming, setShowUpcoming] = useState(true);
      const [showPrevious, setShowPrevious] = useState(false);

      useEffect(() => {
            if (context.trips.value.length === 0) {
                  setLoading(true);
                  getTrips().then((result: any) => {
                        getTripsSignedUp().then((signedUpResult) => {
                              getMyTrips(context.user.value.fullName).then((myTripsResult) => {
                                    const myTripIds = myTripsResult.map((t: any) => {return t.attributes.TurId});
                                    setMyTripIds(myTripIds);
                              })
                              context.trips.set(result);
                              setTripsSignedUp(signedUpResult);
                              setLoading(false);
                        });
                  });

            }
      }, []);

      const formatDate = (d: number) => {
            moment.locale('nb');
            let date = moment(d).format('LLLL'); 
            return date.charAt(0).toUpperCase() + date.slice(1);
      }

      const getSignedUpForTrip = (trip: Trip) => {
            const signedUp: any[] = tripsSignedUp.filter((t: any) => { return t.value === trip.attributes.GlobalID });
            if (signedUp[0]) {
                  return signedUp[0].count;
            } else {
                  return 0;
            }
      }

      if (context.trips.value.length === 0) {
            return <Loading load={loading} text={"Henter turer"}/>;
      }

      const upComingTrips = context.trips.value.filter((trip: Trip) => { return trip.attributes.Tid > moment().toDate() })
      const previousTrips = context.trips.value.filter((trip: Trip) => { return trip.attributes.Tid < moment().toDate() })
      const myTrips = upComingTrips.filter((trip: Trip) => { return myTripIds.includes(trip.attributes.GlobalID) });

      const nextTrip: Trip = upComingTrips.length > 0 ? upComingTrips[0] : null;

    return (
        <div className="flex flex-col w-full p-5">
              <div className="py-6">
              <   div onClick={() => {
                        context.suggest.set(true);
                  }} className="absolute left-0 top-0 p-4 cursor-pointer">
                        Hvor vil du gå?
                  </div>
                  <div className="pb-4 text-2xl">
                        Neste planlagte tur
                  </div>
                        {nextTrip ?
                        <div className="trip-container flex flex-col items-center" onClick={() => history.push(`/${nextTrip.attributes.GlobalID}`, { prevPath: location.pathname })}>
                        {/* <div className="trip-container flex flex-col items-center" onClick={() => context.trip.set(nextTrip)}> */}
                              <div className="flex flex-row">
                                    <div className="pr-3 m-auto">
                                          <Difficulty width={150} diff={nextTrip.attributes.Vanskelighetsgrad} />
                                    </div>
                                    <div className="text-xl">
                                          {nextTrip.attributes.Navn}
                                    </div>
                              </div>
                              <div className="font-bold p-3">
                                    {formatDate(nextTrip.attributes.Tid)}
                              </div>
                              <div className="flex flex-row items-center">
                                    {getSignedUpForTrip(nextTrip)}/{nextTrip.attributes.MaxPameldt}
                                    <img width={20} src={PersonIcon} />
                              </div>
                        </div>
                        :
                        <div className="text-xl">Ingen planlagte turer :(</div>
                        }
            </div>
            <div className="py-6">
                  <div className="pb-4 text-2xl flex flex-row items-center justify-center" onClick={() => {setShowMine(!showMine)}}>
                        Mine påmeldte turer ({myTrips.length})
                        <img style={{transform: showMine ? "rotate(180deg)" : "rotate(0deg)"}} className="ml-3" src={ArrowIcon} />
                  </div>
                  {showMine && myTrips.map((trip: Trip) => {
                              return (
                                    <div key={trip.attributes.Navn} className="trip-container flex flex-row items-center" onClick={() => history.push(`/${trip.attributes.GlobalID}`, { prevPath: location.pathname })}>
                                          <div className={isMobile ? "flex flex-col" : "flex flex-row"}>
                                                <div className="pr-3">
                                                      <Difficulty width={120} diff={trip.attributes.Vanskelighetsgrad} />
                                                </div>
                                                <div className="text-left">
                                                      {trip.attributes.Navn}
                                                </div>
                                          </div>
                                          <div className="flex flex-col ml-auto">
                                                <div className="ml-auto font-bold">
                                                      {moment(trip.attributes.Tid).format("DD. MMM")}
                                                </div>
                                                <div className="pl-4 flex flex-row items-center">
                                                      {getSignedUpForTrip(trip)}/{trip.attributes.MaxPameldt}
                                                      <img width={20} src={PersonIcon} />
                                                </div>
                                          </div>
                                    </div>
                              )
                        })}
                  </div>
            <div></div>
            <div className="py-6">
                  <div className="pb-4 text-2xl flex flex-row items-center justify-center" onClick={() => {setShowUpcoming(!showUpcoming)}}>
                        Kommende turer ({upComingTrips.length})
                        <img style={{transform: showUpcoming ? "rotate(180deg)" : "rotate(0deg)"}} className="ml-3" src={ArrowIcon} />
                  </div>
                  {showUpcoming && upComingTrips.map((trip: Trip) => {
                              return (
                                    <div key={trip.attributes.Navn} className="trip-container flex flex-row items-center" onClick={() => history.push(`/${trip.attributes.GlobalID}`, { prevPath: location.pathname })}>
                                          <div className={isMobile ? "flex flex-col" : "flex flex-row"}>
                                                <div className="pr-3">
                                                      <Difficulty width={120} diff={trip.attributes.Vanskelighetsgrad} />
                                                </div>
                                                <div className="text-left">
                                                      {trip.attributes.Navn}
                                                </div>
                                          </div>
                                          <div className="flex flex-col ml-auto">
                                                <div className="ml-auto font-bold">
                                                      {moment(trip.attributes.Tid).format("DD. MMM")}
                                                </div>
                                                <div className="pl-4 flex flex-row items-center">
                                                      {getSignedUpForTrip(trip)}/{trip.attributes.MaxPameldt}
                                                      <img width={20} src={PersonIcon} />
                                                </div>
                                          </div>
                                    </div>
                              )
                        })}
                  </div>
            <div>
            <div className="py-6">
                  <div className="pb-4 text-2xl flex flex-row items-center justify-center" onClick={() => {setShowPrevious(!showPrevious)}}>
                        Gjennomførte turer ({previousTrips.length})
                        <img style={{transform: showPrevious ? "rotate(180deg)" : "rotate(0deg)"}} className="ml-3" src={ArrowIcon} />
                  </div>
                  {showPrevious && previousTrips.map((trip: Trip) => {
                              return (
                                    <div key={trip.attributes.Navn} className="trip-container flex items-center" onClick={() => history.push(`/${trip.attributes.GlobalID}`, { prevPath: location.pathname })}>
                                          <div className="pr-3">
                                                <Difficulty width={120} diff={trip.attributes.Vanskelighetsgrad} />
                                          </div>
                                          <div>
                                                {trip.attributes.Navn}
                                          </div>
                                          {trip.attributes.Attachments && 
                                                <div className="photo-indicator flex flex-row ml-auto">
                                                       {trip.attributes.Attachments.length}
                                                      <img width={24} src={ImageIcon}/>
                                                </div>
                                          }
                                    </div>
                              )
                        })}
                  </div>
            </div>
            
        </div>
    )
    
}
  
  export default TripList;
