import React, { useState, useRef } from "react";
import "./FileUploader.css";

interface Props {
  callback: (fileList: FileList) => void;
  text: string;
}

const FileUploader = (props: Props) => {
  const [counter, setCounter] = useState(0);
  const inputElement = useRef<HTMLInputElement>(null);

  const fileListCallback = props.callback;

  const stopPropagationAndPreventDefault = (e: React.FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
  };

  const focusFileInputElement = (e: React.KeyboardEvent<HTMLLabelElement>) => {
    if (inputElement.current === null) {
      return;
    }

    if (e.key !== "Enter" && e.key !== " ") {
      return;
    }

    stopPropagationAndPreventDefault(e);
    inputElement.current.click();
  };

  const onFilesDropped = (e: React.DragEvent<HTMLInputElement>) => {
    stopPropagationAndPreventDefault(e);

    const dataTransfer = e.dataTransfer;
    const files = dataTransfer.files;
    fileListCallback(files);
  };

  const onFilesChanged = (e: React.FormEvent<HTMLInputElement>) => {
    stopPropagationAndPreventDefault(e);

    const files = e.currentTarget && e.currentTarget.files;

    if (files) {
      fileListCallback(files);
    }
    setCounter(counter + 1);
  };

  return (
    <label
      className="FileUploader mb-6"
      title="Filopplasting"
      onKeyDown={focusFileInputElement}
      tabIndex={0}
    >
      {props.text}
      <input
        key={counter}
        tabIndex={-1}
        type="file"
        ref={inputElement}
        onDragEnter={stopPropagationAndPreventDefault}
        onDragOver={stopPropagationAndPreventDefault}
        onDrop={onFilesDropped}
        onChange={onFilesChanged}
      />
    </label>
  );
};

export default FileUploader;
