
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import './ModeStyle.css';
import { AppContext } from "../../state/context";
import FileUploader from '../FileUploader/FileUploader';
import { readGpxFile } from '../../services/gpxService';
import { projectPoint, submitTrip } from '../../services/tripService';
import moment from 'moment';

import Plus from '../../assets/plus.svg';
import Difficulty from '../Difficulty/Difficulty';
import { useHistory, useLocation } from "react-router-dom";
import Loading from '../Loading/Loading';
import { Editor } from '@tinymce/tinymce-react';
import { Trip } from '../../model/interfaces';

const Create = (props: {trip?: Trip}) => {

      let initMetadata = {
            title: "",
            desc: "",
            activity: "Bike",
            time: "",
            diff: 2,
            max: "20",
            teamsLink: ""
      }

    const context = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    const [metadata, setMetadata] = useState(initMetadata);
    const [gpxFile, setGpxFile] = useState([]);
    const [gpxFileName, setGpxFileName] = useState("");
    const [loading, setLoading] = useState(false);

      // If editing, display old time      

    useEffect(() => {
      if (props.trip) {
            setMetadata({
                  title: props.trip.attributes.Navn,
                  desc: props.trip.attributes.Detaljer.indexOf("¤%&") > -1 ? props.trip.attributes.Detaljer.replace(/¤%&/g, "<") : props.trip.attributes.Detaljer,
                  activity: "Bike",
                  time: moment(props.trip.attributes.Tid).toString(),
                  diff: parseInt(props.trip.attributes.Vanskelighetsgrad),
                  max: props.trip.attributes.MaxPameldt.toString(),
                  teamsLink: props.trip.attributes.TeamsLink
            });
      } 

          return () => {
            setMetadata(initMetadata);
            resetRoute(); 
          }
    }, []);

    useEffect(() => {
          if (gpxFile.length > 0) {
                readGpxFile(gpxFile).then((result: any) => {
                  context.route.set(result);
                  const file = gpxFile[0] as any;
                  setGpxFileName(file.name);
                });
          }
    }, [gpxFile]);

      const onSubmit = () => {
            if (validate()) {
                  setLoading(true);
                  submitTrip(context, metadata, props.trip).then((resultId) => {
                        history.push(`/${resultId}`, { prevPath: location.pathname })
                        setLoading(false);
                        setMetadata(initMetadata);
                        resetRoute(); 
                        context.trips.set([]);
                        context.create.set(false);
                  });
            }
      }

    const validate = () => {
          return metadata.activity !== "" && metadata.desc !== "" && metadata.time !== "" && metadata.title !== "" &&
                  (props.trip || context.route.value.length > 0) && validateTeamsLink();
    }

    const validateTeamsLink = () => {
          return metadata.teamsLink === "" || metadata.teamsLink === null || metadata.teamsLink.includes("https://teams.microsoft.com/");
    }

    const resetRoute = () => {
          setGpxFileName("");
          setGpxFile([]);
          context.route.set([]);
    }

    return (
        <div className="flex flex-col w-full p-5 relative">
              <Loading text="Oppretter din tur" load={loading}/>
                  <div className="pt-6 pb-4 text-2xl">
                        {props.trip ? "Rediger tur" : "Planlegg ny tur"}
                  </div>
              {/* -------------- GPX input */}
              { props.trip &&
                        <div className="absolute right-0 top-0 p-4 cursor-pointer">
                              <div onClick={() => context.create.set(false)} className="pr-4">Avbryt</div>
                        </div>
                  }
              <div className="step flex flex-col">
                    {/* Tittel */}
                  <div className="text-lg">
                        Tittel
                  </div>
                  <input 
                        value={metadata.title}
                        onChange={(event) => setMetadata({...metadata, title: event.target.value})}
                        className="search-input my-3 p-3 w-full shadow text-sm" 
                        placeholder=""
                  />
                  {/* Beskrivelse */}
                  <div className="py-6">
                  <div className="text-lg">
                        Beskrivelse
                  </div>
                   <Editor
                        initialValue={metadata.desc}
                        init={{
                        height: 500,
                        menubar: false,
                        plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code fullscreen',
                              'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                              'bold italic underline |\
                              image' 
                        }}
                        onEditorChange={(event:any) => setMetadata({...metadata, desc: event})}
                  />
                  </div>
                  {/* Starttid */}
                  <div className="py-6">
                        <div className="text-lg">
                              Starttidspunkt
                        </div>
                        <input 
                              value={moment(metadata.time).format("YYYY-MM-DDTHH:mm")}
                              type="datetime-local" 
                              min={moment().format("YYYY-MM-DDTHH:mm")}
                              className="search-input my-3 p-3 w-full shadow text-sm" 
                              onChange={(event) => setMetadata({...metadata, time: moment(event.target.value).toDate().toString()})}
                        />
                  </div>
                  {/* Maks påmeldt */}
                  <div className="pb-6">
                        <div className="text-lg">
                              Maks antall påmeldte
                        </div>
                        <input 
                              min={1}
                              max={200}
                              type="number"
                              value={metadata.max}
                              onChange={(event) => setMetadata({...metadata, max: event.target.value})}
                              className="search-input my-3 p-3 w-full shadow text-sm" 
                        />
                  </div>
                  {/* Teams-link */}
                  <div>
                        <div className="text-lg">
                              Link til Teams-samtale (valgfritt)
                        </div>
                        {!validateTeamsLink() && 
                              <div style={{color: "red"}}>
                                    URL må være en gyldig Teams-link
                              </div>
                        }
                        <input 
                              value={metadata.teamsLink}
                              onChange={(event) => setMetadata({...metadata, teamsLink: event.target.value})}
                              className="search-input my-3 p-3 w-full shadow text-sm" 
                              placeholder=""
                        />
                  </div>
                  {/* Vanskelighetsgrad */}
                  <div className="text-lg pt-4">Vanskelighetsgrad</div>
                        <div className="p-3 m-auto flex flex-row">
                              <div 
                                    className="text-2xl p-2 cursor-pointer" 
                                    onClick={() => setMetadata({...metadata, diff: metadata.diff > 1 ? metadata.diff-1 : metadata.diff})}
                              >-</div>
                              <Difficulty diff={metadata.diff.toString()} width={200}/>
                              <div 
                                    className="text-2xl p-2 cursor-pointer" 
                                    onClick={() => setMetadata({...metadata, diff: metadata.diff < 5 ? metadata.diff+1 : metadata.diff})}
                              >+</div>
                        </div>
                  </div>
                  {/* Rute */}
              <div className="step flex flex-col">
                  <div className="pb-4 text-xl">
                        Rute
                  </div>
                  {/* Ved redigering, vis melding' */}
                  {props.trip && 
                        <div className="pb-3">Behold ruten som den er, eller lag en ny</div>
                  }
                  {context.route.value.length === 0 &&
                        <React.Fragment>
                              <div onClick={() => context.isDrawing.set(true)} className="route-btn">
                                    {!context.isDrawing.value ? "Start tegning" : "Klikk i kartet for å tegne. Dobbeltklikk for å fullføre"}
                              </div>
                              <div className="separator text-lg py-6 px-4">Eller</div>
                              <FileUploader text="Klikk eller dra GPX-fil hit" callback={(files: any) => setGpxFile(files)}/> 
                              <div className="pb-4">Tips: Opprett ruter på f.eks <a className="underline" href="https://www.strava.com/" rel="noopener noreferrer" target="_blank">Strava</a> eller <a className="underline" href="https://www.skiforeningen.no/utimarka/turplanlegger/" rel="noopener noreferrer" target="_blank">Skiforeningens Turplanlegger</a> og eksporter GPX derfra.</div>
                        </React.Fragment>
                  }
                  {context.route.value.length > 0 && 
                        <div className="route-btn flex">
                              {gpxFileName !== "" ? gpxFileName : "Tegnet rute"}
                              <img 
                                    onClick={() => resetRoute()} 
                                    className="ml-2" 
                                    style={{transform: "rotate(45deg)"}} 
                                    alt="delete" 
                                    src={Plus} 
                                    width={15}
                              />
                        </div> 
                  }
              </div>
            {/* ------------ Submit */}
              <div className="step">
                  <div 
                        className={`button-confirm ${!validate() ? 'disabled' : ''}`}
                        onClick={() => {onSubmit()}}
                  > 
                        {props.trip ? "Oppdater" : "Opprett"}
                  </div>
              </div>
        </div>
    )
    
}
  
  export default Create;
