
/* External Components */
import React, {useState, useContext, useEffect} from 'react';
import './SidebarStyle.css';
import { AppContext } from "../../state/context";
import TripList from '../TripList/TripList';
import Trip from '../Trip/Trip';
import AddIcon from '../../assets/plus.svg';
import { isMobile } from "react-device-detect";
import Create from '../Modes/Create';
import Suggest from '../Modes/Suggest';
import { useHistory, useLocation } from "react-router-dom";
import { getTrip } from '../../services/tripService';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loading from '../Loading/Loading';

const Sidebar = () => {
    const context = useContext(AppContext);
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      if (location.pathname.length > 1) {
            setLoading(true);
            getTrip(location.pathname.substring(1)).then((result: any) => {
                  context.trip.set(result);
                  setLoading(false);
            })
      } else {
            context.trip.set(null);
            context.trips.set([]);
      }
    }, [location.pathname])

    return (
      <div>
        <div style={{width: isMobile ? "100%" : "30%", left: context.showSideBar.value ? 0 : "-100%", top: isMobile ? "50px" : "0"}} className="client-sidebar flex flex-col">
            {/* Hide header if on mobile */}
            {!isMobile && 
                  <div className="logo-container text-2xl">
                        {!context.trip.value &&
                        <div className="absolute right-0">
                              <img 
                                    alt="plus"
                                    style={{transform: context.create.value ? "rotate(45deg)" : "rotate(0)"}}
                                    onClick={() => {
                                          context.create.set(!context.create.value);
                                          context.suggest.set(false);
                                          context.trips.set([]);
                                    }}
                                    width={30} src={AddIcon} 
                                    className="plus cursor-pointer"
                                    />
                        </div>
                        }
                        GeoTur - {context.user.value.fullName}
                  </div>
            }
            {!context.create.value && !context.suggest.value &&
              <div className="flex relative overflow-y-auto h-full">
                  <Loading load={loading} text="Henter tur"/>
                  {!context.trip.value && location.pathname === "/" &&
                        <TripList/>
                  }
                  {context.trip.value &&
                        <Trip/>
                  }
              </div>
            }
            {context.create.value &&
              <div className="flex overflow-y-auto h-full">
                  <Create trip={context.trip.value}/>
              </div>
            }
            {context.suggest.value &&
              <div className="flex overflow-y-auto h-full">
                  <Suggest />
              </div>
            }
              
        </div>
      </div>
    )
    
}
  
  export default Sidebar;
