import { loadModules } from "esri-loader";
import axios from 'axios';
import { Trip } from "../model/interfaces";
import moment from 'moment';

export const submitTrip = (context: any, metadata: any, trip?: Trip): Promise<any> => {   
  return new Promise((resolve, reject) => {
        loadModules([
              "esri/layers/FeatureLayer",
              "esri/Graphic",
        ]).then(([FeatureLayer, Graphic]) => {
            
              const layer = new FeatureLayer({
                    url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur/FeatureServer/0"
              });

              let polyline = null;
            // Create line
            if (context.route.value.length > 0) {
                  polyline = {
                      type: "polyline",
                      paths: [context.route.value.map((point: any) => {
                      // If GPX, coordinates are 4326, if drawn, they are 25833
                        return [parseFloat(point.lon ? point.lon : point[0]), parseFloat(point.lat ? point.lat : point[1])];
                      })],
                      spatialReference: {wkid: context.route.value[0].lon ? 4326 : 25833}
                    };
            }

                const replacedDesc = metadata.desc.replaceAll("<", "¤%&");

                let editObject;

                  // If trip we are editing     
                if (trip) {
                  let editedTrip = trip;

                  editedTrip.attributes.Navn = metadata.title;
                  editedTrip.attributes.Vanskelighetsgrad = metadata.diff;
                  editedTrip.attributes.Navn = metadata.title;
                  editedTrip.attributes.Detaljer = replacedDesc;
                  editedTrip.attributes.MaxPameldt = metadata.max;
                  editedTrip.attributes.TeamsLink = metadata.teamsLink;
                  editedTrip.attributes.Tid = moment(metadata.time).toDate();
                  
                  // If new line, use that, if not, do not update geometry
                  if (polyline) {
                        editedTrip.geometry = polyline;
                  }

                  editObject = {
                        updateFeatures: [editedTrip]
                  }
                } else {
                  let addLine =  new Graphic({
                        geometry: polyline,
                        attributes: {
                            Navn: metadata.title,
                            Vanskelighetsgrad: metadata.diff,
                            Tid: moment(metadata.time).toDate(),
                            Detaljer: replacedDesc,
                            MaxPameldt: metadata.max,
                            TeamsLink: metadata.teamsLink
                        }
                  });

                  editObject = {
                        addFeatures: [addLine]
                  }
                }


              layer.applyEdits(editObject).then((result: any) => {
                  resolve(trip ? result.updateFeatureResults[0].globalId : result.addFeatureResults[0].globalId);
            }).catch((e: any) =>{
                  reject(e);
            });
        });
  });
}

export const submitSuggestion = (context: any, metadata: any): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/layers/FeatureLayer",
                  "esri/Graphic",
            ]).then(([FeatureLayer, Graphic]) => {
                
                  const layer = new FeatureLayer({
                        url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur_Interesseområder/FeatureServer/0"
                  });
    
                  let polygon = null;
                // Create line
                if (context.suggestedPolygon.value.length > 0) {
                  polygon = {
                          type: "polygon",
                          rings: [context.suggestedPolygon.value.map((point: any) => {
                          // If GPX, coordinates are 4326, if drawn, they are 25833
                            return [parseFloat(point.lon ? point.lon : point[0]), parseFloat(point.lat ? point.lat : point[1])];
                          })],
                          spatialReference: {wkid: context.suggestedPolygon.value[0].lon ? 4326 : 25833}
                        };
                }
    
                  let addGraphic = new Graphic({
                        geometry: polygon,
                        attributes: {
                              Detaljer: metadata.desc
                        }
                  });

                  layer.applyEdits({addFeatures: [addGraphic]}).then((result: any) => {
                      resolve(result.addFeatureResults[0].globalId);
                }).catch((e: any) =>{
                      reject(e);
                });
            });
      });
    }

export const deleteTrip = (id: any): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/layers/FeatureLayer",
                  "esri/Graphic"
            ]).then(([FeatureLayer, Graphic]) => {
                  const layer = new FeatureLayer({
                        url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur/FeatureServer/0"
                  });
    
                    const deleteFeature =  new Graphic({
                        attributes: {
                              OBJECTID: id
                        }
                  });
    
                  layer.applyEdits({deleteFeatures: [deleteFeature]}).then((result: any) => {
                      resolve();
                });
            });
      });
    }

export const getTripsSignedUp = (): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/layers/FeatureLayer",
                  "esri/smartMapping/statistics/uniqueValues"
                ]).then(([FeatureLayer, uniqueValues]) => {
                  const layer = new FeatureLayer({
                        url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/ArcGIS/rest/services/GeoTur_P%C3%A5melding/FeatureServer/0"
                  });

                  uniqueValues({
                        layer: layer,
                        field: "TurId"
                  }).then((result: any) => {
                        resolve(result.uniqueValueInfos);
                  })
            });
      });
    }

    export const getTripSignedUp = (tripId: string): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/tasks/QueryTask",
                  "esri/tasks/support/Query",
                ]).then(([QueryTask, Query]) => {
                const query = new Query({
                      where: `TurId='${tripId}'`,
                      outFields: ["Navn", "OBJECTID"],
                      orderByFields: ["Navn asc"]
                });

                  const queryTask = new QueryTask({
                        url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/ArcGIS/rest/services/GeoTur_P%C3%A5melding/FeatureServer/0"
                  });
                  queryTask
                    .execute(query)
                    .then((result: any) => {
                        resolve(result.features);
                    });
                });
      });
    }

export const getTrips = (): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
              "esri/tasks/QueryTask",
              "esri/tasks/support/Query",
              "esri/tasks/support/AttachmentQuery"
            ]).then(([QueryTask, Query, AttachmentQuery]) => {
            const query = new Query({
                  where: `1=1`,
                  outFields: ["*"],
                  returnGeometry: true,
                  orderByFields: ["Tid ASC"],
                  outSpatialReference: {wkid: 25833}
            });
            const attQuery = new AttachmentQuery({
                  where: "1=1"
            })
              const queryTask = new QueryTask({
                url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur/FeatureServer/0",
              });
              queryTask
                .execute(query)
                .then((result: any) => {
                  queryTask.executeAttachmentQuery(attQuery).then((attResult: any) => {
                        result.features.map((trip: Trip) => {
                              if (attResult[trip.attributes.OBJECTID]) {
                                    trip.attributes.Attachments = attResult[trip.attributes.OBJECTID].map((att: any) => { return att.url });
                              }
                        });
                        
                        resolve(result.features);
                  })
                });
            });
      });
}

export const getTrip = (id: string): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
              "esri/tasks/QueryTask",
              "esri/tasks/support/Query",
              "esri/tasks/support/AttachmentQuery"
            ]).then(([QueryTask, Query, AttachmentQuery]) => {
            const query = new Query({
                  where: `GlobalID='${id}'`,
                  outFields: ["*"],
                  returnGeometry: true,
                  outSpatialReference: {wkid: 25833}
            });
              const queryTask = new QueryTask({
                url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur/FeatureServer/0",
              });
              const attQuery = new AttachmentQuery({
                  where: "1=1"
            })
              queryTask
                .execute(query)
                .then((result: any) => {
                  queryTask.executeAttachmentQuery(attQuery).then((attResult: any) => {
                        result.features.map((trip: Trip) => {
                              if (attResult[trip.attributes.OBJECTID]) {
                                    trip.attributes.Attachments = attResult[trip.attributes.OBJECTID].map((att: any) => { return att.url });
                              }
                        });
                        
                        resolve(result.features[0]);
                  })
                  });
            });
      });
}


export const projectPoint = (point: any): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/geometry/projection",
                  "esri/geometry/SpatialReference"
            ]).then(([projection, SpatialReference]) => {
                  projection.load().then(() => {
                        resolve(projection.project(point, new SpatialReference({
                              wkid: 4326
                        })));
                  });
            });
      });
}

export const getGeodesicLength = (line: any): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/geometry/projection",
                  "esri/geometry/geometryEngine"
            ]).then(([projection, geometryEngine]) => {
                  projection.load().then(() => {
                        const projectedLine = projection.project(line, {wkid: 4326});
                        resolve(geometryEngine.geodesicLength(projectedLine) / 1000);
                  });
            });
      });
}


export const getForecast = (point: any): Promise<any> => {   
      return new Promise((resolve, reject) => {

            axios.get(`https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=${point.latitude}&lon=${point.longitude}`).then((result) => {
                  resolve(result);
            }).catch((e) => {
                  reject(e);
            });
      });
}

export const signUp = (trip: Trip, metadata: any): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/layers/FeatureLayer",
                  "esri/Graphic"
            ]).then(([FeatureLayer, Graphic]) => {
                  const layer = new FeatureLayer({
                        url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur_P%C3%A5melding/FeatureServer/0"
                  });
    
                    const add =  new Graphic({
                        attributes: {
                              TurId: trip.attributes.GlobalID,
                            Navn: metadata.name,
                              Allergi: metadata.allergy
                        }
                  });
    
                  layer.applyEdits({addFeatures: [add]}).then((result: any) => {
                      resolve(result.addFeatureResults[0].objectId);
                });
            });
      });
    }

    export const signOff = (id: any): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/layers/FeatureLayer",
                  "esri/Graphic"
            ]).then(([FeatureLayer, Graphic]) => {
                  const layer = new FeatureLayer({
                        url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur_P%C3%A5melding/FeatureServer/0"
                  });
    
                    const deleteFeature =  new Graphic({
                        attributes: {
                              OBJECTID: id
                        }
                  });
    
                  layer.applyEdits({deleteFeatures: [deleteFeature]}).then((result: any) => {
                      resolve();
                });
            });
      });
    }

    export const addAttachment = (file: any, trip: Trip): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
                  "esri/layers/FeatureLayer",
            ]).then(([FeatureLayer]) => {
                  const layer = new FeatureLayer({
                        url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur/FeatureServer/0"
                  });

                  const formData = new FormData();
                  formData.set("attachment", file);
                  
                  layer.addAttachment(trip, formData).then((result: any) => {
                      resolve();
                });
            });
      });
    }

    export const getMyTrips = (name: string): Promise<any> => {   
      return new Promise((resolve, reject) => {
            loadModules([
              "esri/tasks/QueryTask",
              "esri/tasks/support/Query",
            ]).then(([QueryTask, Query]) => {
            const query = new Query({
                  where: `Navn='${name}'`,
                  outFields: ["TurId"]
            });
              const queryTask = new QueryTask({
                url: "https://services.arcgis.com/2JyTvMWQSnM2Vi8q/arcgis/rest/services/GeoTur_P%C3%A5melding/FeatureServer/0",
              });
              queryTask
                .execute(query)
                .then((result: any) => {
                  resolve(result.features);
            });
            });
      });
}

export const getHeightProfile = (geometry: any): Promise<any> => {   
      return new Promise((resolve, reject) => {
            const url = `https://services.geodataonline.no/arcgis/rest/services/Geocache_UTM33_EUREF89/GeocacheTerreng/ImageServer/getSamples`;

            loadModules([
                  "esri/request",
            ]).then(([esriRequest]) => {
                  esriRequest(url, {
                        query: {
                              returnFirstValueOnly: false,
                              geometry: geometry, 
                              geometryType: "esriGeometryPolyline",
                              sampleCount: 150,
                              f: "json"
                        }
                  }).then((res: any) => {
                        resolve(res);
                  });
            });
            
      });
}