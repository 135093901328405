
/* External Components */
import React, {useEffect, useContext, useRef, useState} from 'react';
import { loadCss , loadModules } from 'esri-loader';
import "./MapViewStyle.css";
import { AppContext } from "../../state/context";
import { isMobile } from "react-device-detect";

let mapView: any; 
// const itemId = '9e85681f4c3f463ea4428c0408d3d69e';

const MapView = () => {
  const context = useContext(AppContext);
  const [mapIsLoaded, setMapIsLoaded] = useState(false);

    useEffect(() => {
        loadModules(['esri/config'], {
        }).then(([esriConfig]) => {
          esriConfig.portalUrl = "https://geodata.maps.arcgis.com/";
            // we're not using secure services
            // so save some bytes by not loading/using the identity manager
            // NOTE: this has to be done before even _loading_ other modules
            // esriConfig.request.useIdentity = false;
            // esriConfig.portalUrl = "https://stfk-gis.maps.arcgis.com/";
            loadCss();
            // now we can load the Map and MapView modules
            loadModules([
                'esri/views/MapView', 
                'esri/WebMap',
                "esri/widgets/Expand",
                "esri/widgets/BasemapGallery",
                "esri/widgets/LayerList",
                "esri/widgets/Search",
                ])
            .then(([MapView, WebMap, Expand, BasemapGallery, LayerList, Search]) => {
              var map = new WebMap({
                portalItem: { // autocasts as new PortalItem()
                  id: "82b0519f1df34079b7c49bd5f6f5cc8e"
                }});
              
              mapView = new MapView({
                map,  // References a Map instance
                container: "map"  // References the ID of a DOM element
              });

              mapView.when(() => {
                setMapIsLoaded(true);

                // ------------------------- Add widgets

                // ------------- Search

                const searchWidget = new Search({
                  view: mapView
                });

                //  ------------ Basemap gallery
                const basemapGallery = new BasemapGallery({
                  view: mapView,
                });
                const expandBaseMap = new Expand({
                  expandIconClass: "esri-icon-basemap",
                  expandTooltip: "Bakgrunnskart",
                  view: mapView,
                  content: basemapGallery,
                  expanded: false,
                  group: "top-left"
                });

                // ------------------- Layer List

                var layerList = new LayerList({
                  view: mapView,
                  listItemCreatedFunction: function(event: any) {
                    const item = event.item;
                    if (item.layer.type != "group") {
                      // don't show legend twice
                      item.panel = {
                        content: "legend",
                        open: true
                      };
                    }
                  }
              });
                const expandLayerList = new Expand({
                  expandIconClass: "esri-icon-layer-list",
                  expandTooltip: "Kartlagsliste",
                  view: mapView,
                  content: layerList,
                  expanded: false,
                  group: "top-left"
                });

                mapView.ui.add([expandBaseMap, expandLayerList], {position: "top-left", index: 0});
              });
            })
        }).catch(err => {

        })
    }, []);

      // Adding gpx-track
      useEffect(() => {
      if (context.route.value.length > 0) {
        loadModules([
          'esri/Graphic'
        ])
        .then(([Graphic]) => {
          // Create a symbol for drawing the point
          // mapView.graphics.removeAll();

          // Create a symbol for drawing the point
          var simpleLineSymbol = {
            type: "simple-line",
            color: [0,114,206], // geodata-blue
            width: 2
          };

          var polyline = {
            type: "polyline",
            paths: [context.route.value.map((point: any) => {
              return [parseFloat(point.lon), parseFloat(point.lat)];
            })]
          };

          var polylineGraphic = new Graphic({
            geometry: polyline,
            symbol: simpleLineSymbol
          });

          mapView.graphics.add(polylineGraphic);
          mapView.goTo(polyline);
        });
      } else {
        if (mapView) {
          mapView.graphics.removeAll();
        }
      }
    }, [context.route.value]);

    // Adding suggestion
    useEffect(() => {
      if (context.suggestedPolygon.value.length > 0) {
        loadModules([
          'esri/Graphic'
        ])
        .then(([Graphic]) => {
          // Create a symbol for drawing the point
          // mapView.graphics.removeAll();

          // Create a symbol for drawing the point
          var simpleFillSymbol = {
            type: "simple-fill",
            color: [0,114,206, 0.4], // geodata-blue
            // width: 2
          };

          var polygon = {
            type: "polygon",
            rings: [context.suggestedPolygon.value.map((point: any) => {
              return [parseFloat(point.lon), parseFloat(point.lat)];
            })]
          };

          var polygonGraphic = new Graphic({
            geometry: polygon,
            symbol: simpleFillSymbol
          });

          mapView.graphics.add(polygonGraphic);
          mapView.goTo(polygon);
        });
      } else {
        if (mapView) {
          mapView.graphics.removeAll();
        }
      }
    }, [context.suggestedPolygon.value]);

     // Adding hover-point
     useEffect(() => {
      if (context.hoverPoint.value) {
          loadModules([
            'esri/Graphic'
          ])
          .then(([Graphic]) => {
            // Create a symbol for drawing the point
            // mapView.graphics.removeAll();
            mapView.graphics.removeAll();
  
            var point = {
              type: "point", // autocasts as new Point()
              y: context.hoverPoint.value.y,
              x: context.hoverPoint.value.x,
              spatialReference: {wkid: 25833}
            };
  
            // Create a symbol for drawing the point
            var markerSymbol = {
              type: "simple-marker", // autocasts as new SimpleMarkerSymbol()
              color: [0,114,206],
              outline: {
                // autocasts as new SimpleLineSymbol()
                color: [255, 255, 255],
                width: 2
              }
            };
  
            var pointGraphic = new Graphic({
              attributes: {
                name: "hover"
              },
              geometry: point,
              symbol: markerSymbol
            });
  
            mapView.graphics.add(pointGraphic);
          });
      } else {
        if (mapView) {
          mapView.graphics.removeAll();
        }
      }
    }, [context.hoverPoint.value]);

    // When drawing trip
    useEffect(() => {
      if (context.isDrawing.value) {
        if (isMobile) {
          context.showSideBar.set(false);
        }
      
        loadModules([
          "esri/widgets/Sketch/SketchViewModel"
        ])
        .then(([SketchViewModel]) => {
          const sketchVM = new SketchViewModel({
            layer: mapView.graphics,
            view: mapView,
            polylineSymbol:  {
              type: "simple-line",
              color: [0,114,206],
              width: 2
            }
          });

          sketchVM.create("polyline", {mode: "click"});
          sketchVM.on("create", (event: any) => {
            if (event.state === "complete") {
              context.isDrawing.set(false);
              context.route.set(event.graphic.geometry.paths[0]); 
            }
          });

        });
      } 
    }, [context.isDrawing.value]);

    // When drawing suggestion
    useEffect(() => {
      if (context.isDrawingSuggestion.value) {
        if (isMobile) {
          context.showSideBar.set(false);
        }
      
        loadModules([
          "esri/widgets/Sketch/SketchViewModel"
        ])
        .then(([SketchViewModel]) => {
          const sketchVM = new SketchViewModel({
            layer: mapView.graphics,
            view: mapView,
            polygonSymbol:  {
              type: "simple-fill",
              color: [0,114,206],
            }
          });

          sketchVM.create("polygon", {mode: "click"});
          sketchVM.on("create", (event: any) => {
            if (event.state === "complete") {
              context.isDrawingSuggestion.set(false);
              context.suggestedPolygon.set(event.graphic.geometry.rings[0]); 
            }
          });

        });
      } 
    }, [context.isDrawingSuggestion.value]);
      

    // Zoom to trip
    useEffect(() => {
      if (context.trip.value) {
        if (mapView) {
          mapView.goTo(context.trip.value.geometry);
        }
      }
    }, [context.trip.value, mapIsLoaded])
    
    return (
      <div>
        <div style={{width: isMobile ? "100%" : "70%", top: isMobile ? "50px" : "0"}} className="client-mapContainer shadow" id="map"></div>
      </div>
    )
    
}
  
  export default MapView;
