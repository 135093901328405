
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import { useHistory, useLocation } from "react-router-dom";
import ImageGallery from 'react-image-gallery';

import { AppContext } from "../../state/context";
import { Trip } from '../../model/interfaces';
import Difficulty from '../Difficulty/Difficulty';
import Weather from '../Weather/Weather';
import Directions from '../Directions/Directions';
import SignUp from '../SignUp/SignUp';
import PhotoUpload from '../PhotoUpload/PhotoUpload';
import Loading from '../Loading/Loading';
import "./TripStyle.css";

import { projectPoint, deleteTrip, getGeodesicLength } from '../../services/tripService';

import moment from 'moment';
import 'moment/locale/nb';
import "react-image-gallery/styles/css/image-gallery.css";

import teams from '../../assets/microsoft-teams-1.svg';
import share from '../../assets/share-button.svg';
import HeightProfile from '../HeightProfile/HeightProfile';

const TripView = () => {
      const context = useContext(AppContext);
      const history = useHistory();
      const location = useLocation();

      const [latLng, setLatLng] = useState(null);
      const [showDeleteTrip, setShowDeleteTrip] = useState(false);
      const [loading, setLoading] = useState(false);
      const [showCopied, setShowCopied] = useState(false);

      const [geodesicLength, setGeodesicLength] = useState<any>(null);

      const trip: Trip = context.trip.value;
      const d = new Date(trip.attributes.Tid);
      moment.locale('nb');
      let date = moment(d).format('LLLL');
      date = date.charAt(0).toUpperCase() + date.slice(1);

      useEffect(() => {
            const firstPoint = trip.geometry.getPoint(0, 0);

            projectPoint(firstPoint).then((result: any) => {
                  setLatLng(result);
            })

            getGeodesicLength(trip.geometry).then((res) => {
                  setGeodesicLength(res);
            })

      }, []);

      useEffect(() => {
            if (showCopied) {
                  setTimeout(() => {
                        setShowCopied(false);
                  }, 3000);
            }
      }, [showCopied])

      const onConfirmDelete = () => {
            setLoading(true);
            deleteTrip(trip.attributes.OBJECTID).then(() => {
                  setLoading(false);
                  history.push(`/`, { prevPath: location.pathname })
                  context.trip.set(null);
                  context.trips.set([]);
            });
      }

      const tripHasHappened = moment(trip.attributes.Tid).valueOf() < moment().valueOf(); 
      const desc = trip.attributes.Detaljer.indexOf("¤%&") > -1 ? trip.attributes.Detaljer.replace(/¤%&/g, "<") : trip.attributes.Detaljer;

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col items-center p-8 relative">
                  <Loading text={"Sletter tur"} load={loading}/>
                  <div onClick={() => {
                        history.push(`/`, { prevPath: location.pathname })
                        context.trip.set(null);
                        context.trips.set([]);
                  }} className="absolute left-0 top-0 p-4 cursor-pointer">
                        Tilbake
                  </div>
                  {/* If user is the creator, display delete/edit button */}
                  { trip.attributes.Creator === context.user.value.username &&
                        <div className="absolute right-0 top-0 p-4 cursor-pointer flex flex-row">
                              <div onClick={() => context.create.set(true)} className="pr-4">Rediger</div>
                              {showDeleteTrip ? <div>
                                    <button className="ml-2 mb-2 upload-btn" onClick={() => {onConfirmDelete()}}>Slett tur</button>
                                    <button className="ml-2 mb-2 cancel-btn" onClick={() => {setShowDeleteTrip(false)}}>Avbryt</button>
                              </div> : <div onClick={() => {setShowDeleteTrip(true)}}>Slett</div>}
                        </div>
                  }
                  <div className="text-3xl p-4 flex flex-row items-center" >
                        {trip.attributes.Navn}
                  </div>
                  <div>
                        {date}
                  </div>
                  <div className="py-4">
                        <Difficulty width={300} diff={trip.attributes.Vanskelighetsgrad.toString()} />
                  </div>
                  <div className="flex flex-row p-4 text-xl items-center">
                        <div >
                              {geodesicLength && geodesicLength.toFixed(1)} km
                        </div>
                        <div className="h-6">
                              <img alt="" onClick={() => {
                                    navigator.clipboard.writeText(window.location.href);
                                    setShowCopied(true);
                              }} className="pl-3 cursor-pointer h-full" src={share}/>
                        </div>
                  </div>
                  {showCopied ? <div>Link til tur kopiert!</div> : null}
                  {trip.attributes.TeamsLink && 
                        <a href={trip.attributes.TeamsLink} target="_blank" rel="noopener noreferrer" className="flex flex-row items-center">
                              <img className="pr-2" width={30} src={teams}/>
                              Åpne samtale i Teams
                        </a>
                  }
                  <div className="trip-desc text-justify py-5" dangerouslySetInnerHTML={{__html: desc}}>
                  </div>
                  {trip &&
                        <HeightProfile trip={trip}/>
                  }
                  {!tripHasHappened && 
                        <div className="widget-container flex flex-row w-full pt-6">
                              {trip &&
                                    <Weather trip={trip} latLng={latLng}/>
                              }
                              {trip && 
                                    <Directions trip={trip} latLng={latLng} />      
                              }
                        </div>
                  }
                  {trip.attributes.Attachments &&
                        <div className="max-w-full">
                              <ImageGallery showPlayButton={false} items={trip.attributes.Attachments.map((url: string) => {
                                    return {
                                          original: url,
                                    }
                              })}/>
                        </div>
                  }
                  {tripHasHappened &&
                        <PhotoUpload/>
                  }
                  {!tripHasHappened && 
                        <div className="w-full">
                              <SignUp/>
                        </div>
                  }
            </div>
        </div>
    )
    
}
  
  export default TripView;
