
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import { AppContext } from "../../state/context";
import { Trip } from '../../model/interfaces';
import { getHeightProfile } from '../../services/tripService';
import { YAxis, Tooltip, Area, AreaChart, ResponsiveContainer } from 'recharts';
import "./HeightProfileStyle.css";

const HeightProfile = (props: {trip: Trip}) => {

      const context = useContext(AppContext);
      const [heights, setHeights] = useState([]);
      const [currentHeight, setCurrentHeight] = useState(null);

      useEffect(() => {
            getHeightProfile(props.trip.geometry).then((result) => {
                  setHeights(result.data.samples);
            })
      }, []);

      if (!heights) {
            return <div className="widget">Laster høydeprofil..</div>;
      }

      const data = heights.map((sample: any, index: number) => {
                  return {x: index, y: Math.floor(sample.value), location: sample.location};
            });

    return (
      <div className="widget flex flex-col items-center py-8 w-full">
            {currentHeight && <div className="absolute top-0">{currentHeight} moh</div>}
            <ResponsiveContainer width="100%" height={100}>
                  <AreaChart
                        data={data}
                        onMouseMove={(e:any) => {
                              if (e && e.activePayload) {
                                    context.hoverPoint.set(e.activePayload[0].payload.location);
                                    setCurrentHeight(e.activePayload[0].payload.y);
                              }
                        }}
                        onMouseOut={(e:any) => {
                                    context.hoverPoint.set(null);
                                    setCurrentHeight(null);
                        }}
                  >
                        <YAxis domain={['dataMin', 'dataMax']}/>
                        <Area fillOpacity="1" dataKey="y" stroke="rgba(0,114,206,1)" fill="rgba(0,114,206,1)" />
                  </AreaChart>
            </ResponsiveContainer>
      </div>
    );
    
}
  
  export default HeightProfile;
