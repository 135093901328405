
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import "./LoadingStyle.css";

const Loading = (props: {load: boolean, text: string}) => {

      if (!props.load) {
            return null;
      }

      return (
            <div className="loader flex flex-col absolute top-0 justify-center items-center z-50">
                  <div className="text-xl">
                        {props.text}
                  </div>
                  <div>
                        <svg width="15%" className="mx-auto overflow-visible" viewBox="0 0 85 79" version="1.1">
                              <g id="logo" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                    <g fill-rule="nonzero">
                                          <polygon className="layer layer1" id="path847" fill="#0072CE" points="0.06094252 21.1089422 42.4229418 42.4229422 84.8519388 21.3759422 42.4229418 0.0609424"></polygon>
                                          <polygon className="layer layer2" id="path849" fill="#0098FF" points="17.9559418 30.0819423 0.12794252 39.0159422 42.3559418 60.2639422 84.7849388 39.0829423 67.1529418 30.1429423 42.4229418 42.4229423"></polygon>
                                          <polygon className="layer layer3" id="path851" fill="#00BBFF" points="17.8519418 47.8829422 0.16094252 56.9229422 42.4559418 78.0369422 84.8849388 56.8559422 67.1749418 47.8469422 42.3899418 60.2639422"></polygon>
                                    </g>
                              </g>
                        </svg>       
                  </div>
            </div>
      )
    
}
  
  export default Loading;
