
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import { AppContext } from "../../state/context";
import Diff1 from '../../assets/diff1.svg';
import Diff2 from '../../assets/diff2.svg';
import Diff3 from '../../assets/diff3.svg';
import Diff4 from '../../assets/diff4.svg';
import Diff5 from '../../assets/diff5.svg';

const Difficulty = (props: {diff: string, width: number}) => {

      switch(props.diff) {
        case "1":
          return <img width={props.width} src={Diff1}/>
        case "2":
          return <img width={props.width} src={Diff2}/>
        case "3":
          return <img width={props.width} src={Diff3}/>
        case "4":
          return <img width={props.width} src={Diff4}/>
        case "5":
          return <img width={props.width} src={Diff5}/>
      }

      return null;

    
}
  
  export default Difficulty;
