
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import { AppContext } from "../../state/context";
import { Trip } from '../../model/interfaces';
import Maps from "../../assets/maps.svg"
import createEnturService, { TripPattern } from '@entur/sdk'

const Directions = (props: {trip: Trip, latLng: any}) => {
      const context = useContext(AppContext);
      const [route, setRoute] = useState<any>(null);
      const [noRoute, setNoRoute] = useState(false);
      const [link, setLink] = useState(""); 

      useEffect(() => {
            if (props.latLng) {
                  const service = createEnturService({ clientName: 'geodata-geotur' });
                  service.getFeaturesReverse({latitude: props.latLng.latitude, longitude: props.latLng.longitude}).then((result) => {
                        if (result.length > 0) {
                              service.findTrips("Schweigaards gate 28, 0191 Oslo", result[0].properties.label as string).then((routeResult: any) => {
                                    if (routeResult.length > 0 ) {
                                          setRoute(routeResult[0]);
                                          setLink(`https://www.google.com/maps/dir/?api=1&origin=Schweigaards+gate+28,+0191+Oslo&destination=${result[0].properties.label}&travelmode=transit`)
                                    } else {
                                          setNoRoute(true);
                                    }
                              });
                        }
                  })
            }
      }, [props.latLng]);

      const getMode = (mode: string) => {
            switch(mode) {
                  case "foot":
                        return "Gå";
                  case "metro":
                        return "T-bane";
                  case "bus": 
                        return "Buss";
                  case "tram":
                        return "Trikk";
                  case "rail":
                        return "Tog";
                  case "flight":
                        return "Fly";
                  case "plane":
                        return "Fly";
                  default: 
                        return "Ukjent";
            }
      } 

      if (noRoute) {
            return <div className="widget">Finner ingen rute</div>;
      }

      if (!route) {
            return <div className="widget">Laster rute..</div>;
      }

    return (
      <div className="widget flex flex-col items-center ml-2">
            <div className="pb-2">Fra Geodata: </div>
            {route.legs.map((leg: any, index: number) => {
                  if (index === route.legs.length - 1 && leg.mode === "foot") {
                        return null;
                  } 
                  
                 return (
                       <div key={index} className="w-full text-left">
                             {index + 1}. {getMode(leg.mode)} til {leg.toPlace.name}
                       </div>
                 ) 
            })
            }
            <a className="bg-white rounded-md underline p-1 m-4" href={link} rel="noopener noreferrer" target="_blank">
                  <img width={30} src={Maps}/>
            </a>
      </div>
    );
    
}
  
  export default Directions;
