
/* External Components */
import React, {useState, useContext, useEffect} from 'react';
import './ModeStyle.css';
import { AppContext } from "../../state/context";
import { submitSuggestion } from '../../services/tripService';

import Plus from '../../assets/plus.svg';
import { useHistory, useLocation } from "react-router-dom";
import Loading from '../Loading/Loading';
import { Editor } from '@tinymce/tinymce-react';

const Suggest = () => {

      let initMetadata = {
            desc: "",
      }

    const context = useContext(AppContext);
    const history = useHistory();
    const location = useLocation();

    const [metadata, setMetadata] = useState(initMetadata);
    const [loading, setLoading] = useState(false);

      // If editing, display old time      

    useEffect(() => {
      return () => {
            setMetadata(initMetadata);
            resetDrawing(); 
      }
    }, []);

      const onSubmit = () => {
            if (validate()) {
                  setLoading(true);
                  submitSuggestion(context, metadata).then(() => {
                        setLoading(false);
                        setMetadata(initMetadata);
                        resetDrawing(); 
                        context.suggest.set(false);
                  });
            }
      }

    const validate = () => {
          return context.suggestedPolygon.value.length > 0;
    }

    const resetDrawing = () => {
          context.suggestedPolygon.set([]);
    }

    return (
        <div className="flex flex-col w-full p-5 relative">
              <Loading text="Registrerer forslag" load={loading}/>
                  <div onClick={() => {
                        resetDrawing();
                        context.suggest.set(false);
                  }} className="absolute left-0 top-0 p-4 cursor-pointer">
                        Tilbake
                  </div>
                  <div className="pt-6 pb-4 text-2xl">
                        Hvor har du lyst til å gå?
                  </div>
                  <div className="pt-6 pb-4 text-lg">
                        Her kan du legge inn områder hvor du ønsker å gå tur. 
                        På den måten blir det lettere for oss å planlegge turer der vi vet at folk ønsker å gå. 
                        Kartlag over eksisterende forslag kan skrus på å i kartlagsvelgeren. På forhånd takk!
                  </div>
 
              <div className="step flex flex-col">
                  {/* Rute */}
                  <div className="step flex flex-col">
                        {context.suggestedPolygon.value.length === 0 &&
                              <div onClick={() => context.isDrawingSuggestion.set(true)} className="route-btn">
                                    {!context.isDrawingSuggestion.value ? "Start tegning" : "Klikk i kartet for å tegne. Dobbeltklikk for å fullføre"}
                              </div>
                        }
                        {context.suggestedPolygon.value.length > 0 && 
                              <div className="route-btn flex">
                                    Tegnet område
                                    <img 
                                          onClick={() => resetDrawing()} 
                                          className="ml-2" 
                                          style={{transform: "rotate(45deg)"}} 
                                          alt="delete" 
                                          src={Plus} 
                                          width={15}
                                    />
                              </div> 
                        }
                  </div>
                  {/* Beskrivelse */}
                  <div className="py-6">
                        <div className="text-lg">
                              Beskrivelse
                        </div>
                        <input 
                              value={metadata.desc}
                              onChange={(event) => setMetadata({...metadata, desc: event.target.value})}
                              className="search-input my-3 p-3 w-full shadow text-sm" 
                              placeholder=""
                        />
                  </div>
            </div>
            {/* ------------ Submit */}
              <div className="step">
                  <div 
                        className={`button-confirm ${!validate() ? 'disabled' : ''}`}
                        onClick={() => {onSubmit()}}
                  > 
                       Opprett
                  </div>
              </div>
        </div>
    )
    
}
  
  export default Suggest;
