export const readGpxFile = (file: any) => {
      return new Promise((resolve, reject) => {
            file[0].text().then((result: any) => {
                  const parser = new DOMParser();
                  const xmlDoc = parser.parseFromString(result,"text/xml");
                  const track = xmlDoc.getElementsByTagName("trkpt");
                  const formattedTrack = Array.from(track).map((trackPoint: any) => {
                        return {
                              lat: trackPoint.attributes["lat"].value,
                              lon: trackPoint.attributes["lon"].value
                        }
                  });

                  resolve(formattedTrack);
            })
      });
}