import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { loadCss , loadModules } from 'esri-loader';

import './App.css';
import MapView from './components/MapView/MapView';
import Sidebar from './components/SideBar/SideBar';
import { AppContext } from "./state/context";
import { isMobile } from "react-device-detect";
import AddIcon from './assets/plus.svg';
import Burger from './assets/hamburger.svg';

function App() {
  const [trip, setTrip] = useState(null);
  const [trips, setTrips] = useState([]);
  const [showSideBar, setShowSideBar] = useState(true);
  // When drawing trip
  const [create, setCreate] = useState(false); 
  const [route, setRoute] = useState([]);
  const [isDrawing, setIsDrawing] = useState(false);

  // When drawing suggestion
  const [suggest, setSuggest] = useState(false);
  const [suggestedPolygon, setSuggestedPolygon] = useState([]);
  const [isDrawingSuggestion, setIsDrawingSuggestion] = useState(false);

  const [portal, setPortal] = useState(null);
  const [user, setUser] = useState(null);
  const [hoverPoint, setHoverPoint] = useState(null);

  const store = { 
    trip: {value: trip, set: setTrip},
    trips: {value: trips, set: setTrips},
    showSideBar: { value: showSideBar, set: setShowSideBar},
    create: { value: create, set: setCreate},
    suggest: { value: suggest, set: setSuggest},
    suggestedPolygon: { value: suggestedPolygon, set: setSuggestedPolygon},
    isDrawingSuggestion: { value: isDrawingSuggestion, set: setIsDrawingSuggestion},
    route: { value: route, set: setRoute},
    isDrawing: { value: isDrawing, set: setIsDrawing },
    portal: {value: portal, set: setPortal},
    user: {value: user, set: setUser},
    hoverPoint: {value: hoverPoint, set: setHoverPoint}
  }

  useEffect(() => {
    loadModules(['esri/config'], {
    }).then(([esriConfig]) => {
      esriConfig.portalUrl = "https://geodata.maps.arcgis.com/";
        // we're not using secure services
        // so save some bytes by not loading/using the identity manager
        // NOTE: this has to be done before even _loading_ other modules
        // esriConfig.request.useIdentity = false;
        // esriConfig.portalUrl = "https://stfk-gis.maps.arcgis.com/";
        loadCss();
        // now we can load the Map and MapView modules
        loadModules([
            "esri/identity/IdentityManager",
            "esri/identity/OAuthInfo",
            "esri/portal/Portal"
            ])
        .then(([IdentityManager, OAuthInfo, Portal]) => {
          var info = new OAuthInfo({
            appId: "CSLOMATVRzueHnbG",
            // Uncomment the next line and update if using your own portal
            portalUrl: esriConfig.portalUrl,
            // Uncomment the next line to prevent the user's signed in state from being shared
            // with other apps on the same domain with the same authNamespace value.
            //authNamespace: "portal_oauth_inline",
            popup: false
          });
          const portal = new Portal(esriConfig.portalItem);

          IdentityManager.registerOAuthInfos([info]);
          IdentityManager.getCredential(esriConfig.portalUrl + "/sharing").then((res: any) => {
            portal.load().then(() => {
              var queryParameters = {
                query: "username:" + portal.user.username
              };
           
              portal.queryUsers(queryParameters).then((queryResults: any) => {
                setUser(queryResults.results[0]);
              });
            })
          })
          
        })
    }).catch(err => {

    })
}, []);


  return (
    <AppContext.Provider value={store}>
      {user &&
      <Router>
      <div className="App">
      {isMobile && 
          <div className="logo-container text-2xl items-center">
            <div onClick={() => setShowSideBar(!showSideBar)} className="absolute">
              <img width={30} src={Burger} className="hamburger"/>
            </div>
            {!trip &&
              <div 
                onClick={() => {
                  setCreate(!create);
                  setTrips([]);
                  setShowSideBar(true);
                }} 
                className="absolute right-0"
                >
                <img 
                  alt="plus"
                  style={{transform: create ? "rotate(45deg)" : "rotate(0)"}}
                  width={30} src={AddIcon} className="plus"/>
              </div>
            }
                GeoTur
          </div>
        }
          <MapView/>
          <Sidebar/>
      </div>
        </Router>
    }
    </AppContext.Provider>
  );
}

export default App;
