
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import { AppContext } from "../../state/context";
import moment from 'moment';
import 'moment/locale/nb';
import { addAttachment, getTrip } from '../../services/tripService';
import "react-image-gallery/styles/css/image-gallery.css";
import { useHistory, useLocation } from "react-router-dom";
import FileUploader from '../FileUploader/FileUploader';
import Loading from '../Loading/Loading';
import { Trip } from '../../model/interfaces';

const PhotoUpload = () => {
      const context = useContext(AppContext);

      const [file, setFile] = useState(null);
      const [loading, setLoading] = useState(false);
      
      const trip: Trip = context.trip.value;

      const uploadAttachment = (files: any) => {
            if (files[0].type === "image/png" || files[0].type === "image/jpeg") {
                  setFile(files[0]);
            }
      }
      
      const onUploadPhoto = () => {    
                    
            setLoading(true);
            addAttachment(file, trip).then(() => {
                  getTrip(trip.attributes.GlobalID.toString()).then((result: any) => {
                        context.trip.set(result);
                        setFile(null);
                        setLoading(false);
                  })
            });         
      }


    return (
        <div className="w-full pt-8">
            {file && 
                  <div className="flex flex-row relative">
                        <Loading load={loading} text={"Laster opp bilde"}/>
                        <div className="photo-upload-container flex-grow">
                              <img src={URL.createObjectURL(file)} />
                        </div>
                        <div className="photo-upload-container flex-grow flex flex-col">
                              <button className="ml-2 mb-2 upload-btn" onClick={() => {onUploadPhoto()}}>Last opp</button>
                              <button className="ml-2 mb-2 cancel-btn" onClick={() => setFile(null)}>Avbryt</button>
                        </div>
                  </div>
            }
            {!file && 
                  <FileUploader text="Last opp bilder her (JPG/PNG)" callback={(files: any) => { uploadAttachment(files)}}/>
            }
      </div>        
    )
    
}
  
  export default PhotoUpload;
