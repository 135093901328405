
/* External Components */
import React, {useState, useContext, useEffect, useRef} from 'react';
import { getTripSignedUp, signOff, signUp } from '../../services/tripService';
import { AppContext } from "../../state/context";
import "./SignUp.css";
import Loading from '../Loading/Loading';

const SignUp = () => {
      const context = useContext(AppContext);

      const initMetadata = {
            name: context.user.value.fullName,
            allergy: ""
      }

    const [metadata, setMetadata] = useState(initMetadata);
    const [signedUp, setSignedUp] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      getSignedUp();

          return () => {
            setMetadata(initMetadata);
          }
    }, []);

    const getSignedUp = () => {
      getTripSignedUp(context.trip.value.attributes.GlobalID).then((result: any) => {
            setSignedUp(result);
            setLoading(false);
      })
    }

    const isSignedOn = () => {
          return signedUp.map((feature: any) => {return feature.attributes.Navn}).includes(context.user.value.fullName);
    }

      const onSubmit = () => {
            setLoading(true);
            if (!isSignedOn() && validate()) {
                  signUp(context.trip.value, metadata).then((resultId) => {
                        getSignedUp();
                  })
            } else {
                  const feature: any = signedUp.filter((feature: any) => { return feature.attributes.Navn === context.user.value.fullName})[0]
                  const id = feature.attributes.OBJECTID;
                  signOff(id).then(() => {
                        getSignedUp();
                  })
            }
      }

      const validate = () => {
            return isSignedOn() || signedUp.length < context.trip.value.attributes.MaxPameldt; 
      }

    return (
        <div className="flex flex-col w-full py-10 relative">
            <Loading load={loading} text={isSignedOn() ? "Melder deg av" : "Melder deg på"} />
            <div>
            {signedUp.length} av maks {context.trip.value.attributes.MaxPameldt} påmeldt 
            </div>
            {/* ------------- List of signed up */}
            {signedUp.length > 0 &&
                  <div className="signedUp-list mt-3 p-3 overflow-hidden">
                        {signedUp.map((feature: any, index: number) => {
                              return <div key={index} className="flex flex-col">
                                    <div className="flex flex-row">
                                          {feature.attributes.Navn} 
                                    </div>
                              </div>
                        })}
                  </div>
            }
            {/* ----------- Input */}
            {!isSignedOn() &&
              <div className="flex flex-col pt-5">
                  <input 
                        value={metadata.allergy}
                        onChange={(event) => setMetadata({...metadata, allergy: event.target.value})}
                        className="search-input my-3 p-3 w-full shadow text-sm" 
                        placeholder="Allergi"
                        />
            </div>
            }
            {/* ------------ Submit */}
              <div className="pt-5">
                  <div 
                        className={`button-confirm ${!validate() ? 'disabled' : ''}`}
                        onClick={() => {onSubmit()}}
                  > 
                        {isSignedOn() ? "Meld meg av" : "Meld meg på"}
                  </div>
              </div>
        </div>
    )
    
}
  
  export default SignUp;
